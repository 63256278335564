(function ($) {
  $(function () {
    //ACF Sector partner's slider
    $(".sector-slider").slick({
      slidesToShow: 7,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        /* {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: '100px',
                    }
                }, */
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            //centerPadding: '100px',
          },
        },
      ],
    });

    //Resources slider
    $(".resource_slider").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      autoplay: false,
      infinite: false,
      appendArrows: ".arrow-holder",
      nextArrow: $(".arrow .next"),
      prevArrow: $(".arrow .prev"),

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            //centerMode: true
          },
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
          },
        },
      ],
    });

    //logo slider
    $('.autoslide').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 1,
                  centerMode: true,
                  centerPadding: '100px',
              }
          },
      ]
    });
    
    //latest news
    $('.slider-featured-post').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 2,
                  dots: true,
                  centerMode: false,
              }
          },
          {
              breakpoint: 560,
              settings: {
                  slidesToShow: 1,
                  dots: true,
                  centerMode: true,
                  centerPadding: '65px',
              }
          },
      ]
    });
    //ACF FULL WIDTH SLIDER

    //01. Desktop Slider
    const bigDevice = window.matchMedia("(min-width: 991px)");

    function handleDesktopDeviceChange(e) {
      if (e.matches) {
        $(".fw_slider_old").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          customPaging: function (slider, i) {
            var title = $(slider.$slides[i].innerHTML)
              .find("div[data-title]")
              .data("title");
            return '<a class="pager__item"> ' + title + " </a>";
          },
          arrows: false,
          autoplay: true,
          fade: true,
          cssEase: "linear",
          autoplaySpeed: 4000,
          asNavFor: ".fw_slider-nav",
        });
        $(".fw_slider-nav").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          asNavFor: ".fw_slider",
          dots: false,
          arrows: false,
          focusOnSelect: true,
        });
      }
    }

    // Register event listener
    bigDevice.addListener(handleDesktopDeviceChange);

    // Initial check
    handleDesktopDeviceChange(bigDevice);

    //02. Mobile
    const smallDevice = window.matchMedia("(max-width: 990px)");

    function handleMobileDeviceChange(e) {
      if (e.matches) {
        $(".fw_slider").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          autoplay: true,
          fade: true,
          cssEase: "linear",
          autoplaySpeed: 4000,
          asNavFor: ".fw_slider-nav",
        });
        $(".fw_slider-nav").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          asNavFor: ".fw_slider",
          dots: false,
          arrows: false,
          centerMode: true,
          fade: true,
          cssEase: "linear",
          centerPadding: "120px",
          focusOnSelect: true,
        });
      }
    }

    // Register event listener
    smallDevice.addListener(handleMobileDeviceChange);

    // Initial check
    handleMobileDeviceChange(smallDevice);
  });
})(jQuery);
